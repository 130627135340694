import { $, closeTask, MAIN_LIST, openTask, SortMethod, startScan } from "./globals.js";
import { TodoItem } from "./TodoItem.js";
import { TodoList } from "./TodoList.js";
import "./polyfills.js";

try {
    (window as any)["BarcodeDetector"].getSupportedFormats();
} catch {
    console.log( "polyfill BarcodeDetector" );
    loadPolyFill();
}
async function loadPolyFill()
{
    await import("./lib/zbar-wasm/main.js");
    const BarcodeDetector = await import( "./lib/barcode-detector-polyfill/main.js" );
    (window as any)["BarcodeDetector"] = BarcodeDetector.BarcodeDetectorPolyfill;
}

window.addEventListener("load", load);

function getList(): string
{
    const hash = window.location.hash.replace("#!/", "").split("/");
    const list = hash[0] || MAIN_LIST;
    $("nav .title")[0].textContent = list;

    // #!/list/task
    return list;
}

function load(): void
{

    const pStart = { x: 0, y: 0 };
    const pStop = { x: 0, y: 0 };

    const list = getList();
    const todoList = new TodoList( list );

    function swipeStart(e: TouchEvent) {
        if (typeof e["targetTouches"] !== "undefined") {
          const touch = e.targetTouches[0];
          pStart.x = touch.pageX;
          pStart.y = touch.pageY;
        }
      }
  
      function swipeMove(e: TouchEvent) {
          if (typeof e["changedTouches"] !== "undefined") {
            const touch = e.changedTouches[0];
            pStop.x = touch.pageX;
            pStop.y = touch.pageY;
          }
  
          swipeCheck();
      }    
      
      function swipeEnd(e: TouchEvent) {
        if (typeof e["changedTouches"] !== "undefined") {
          const touch = e.changedTouches[0];
          pStop.x = touch.pageX;
          pStop.y = touch.pageY;
        }
      
        if ( swipeCheck() )
        {
          todoList.load( getList() );
        }
  
        // Reset opacity
        document.body.style.opacity = "";
        document.body.style.paddingTop = `0cm`  
    }
      
      function swipeCheck() {
        const changeY = pStart.y - pStop.y;
        const changeX = pStart.x - pStop.x;
        const factor = isPullDown(changeY, changeX);
        // Set some opacity
        document.body.style.opacity = `${factor === 1 ? 1 : (1 - factor / 2)}`;
        document.body.style.paddingTop = `${factor}cm`
        return factor === 1;
      }
      
      function isPullDown(dY: number, dX: number) {
        // methods of checking slope, length, direction of line created by swipe action
        if (dY >= 0)
          return 0;
  
        const travel = window.innerHeight * -0.4;
  
        const angle = Math.abs(dX) / Math.abs(dY);
  
        if (angle <= 0.3 && dY <= travel)
          return 1;
  
        const yFactor = Math.min( 1, dY / travel );
        const xFactor = 1 - Math.min(1, angle - 0.3);
  
        return xFactor * yFactor;
      }
      
      document.addEventListener(
        "touchstart",
        swipeStart,
        false
      );
      document.addEventListener(
        "touchmove",
        swipeMove,
        false
      );
      document.addEventListener(
        "touchend",
        swipeEnd,
        false
      );

    todoList.addEventListener("close", (e) => {
        closeTask();
    });
    // Ways to "navigate" to another list
    const navigate = ( listName: string): void => {
        if (listName)
            history.pushState(listName, document.title, `#!/${listName}` );
        else
            history.pushState(listName, document.title, window.location.pathname );
        todoList.load( getList(), true );
    }

    todoList.addEventListener("navigate", (e) => {
        const todoItem = (e as CustomEvent<TodoItem>).detail;
        // console.log("nav", todoItem.id, todoItem.label );
        navigate(todoItem.label);
    });
    $("#back")[0].addEventListener( "click", () => {
        // Remove the hash to load index
        navigate("");
    } );
    window.addEventListener("hashchange", () => {
        // Hash changed; reload list and/or add task
        navigate( getList() );
    } );
    $("#reload")[0].addEventListener( "click", () => {
        todoList.load( getList() );
    } );

    $("#expand")[0].addEventListener( "click", () => {
        todoList.todos.forEach( (todoItem) => {
            if ( todoItem.children.size && !todoItem.expanded )
            {
                todoItem.expanded = true;
                todoItem.dispatchEvent(new CustomEvent( "change" ));
            }
        });
    });

    $("#edittask")[0].addEventListener( "click", () => {
        const todoItemNode = $("[aria-selected=true]")[0];
        const todoItemIdx = todoList.todos.findIndex( todoItem => todoItem.id === todoItemNode?.id );
        const todoItem = todoItemIdx >= 0 ? todoList.todos[todoItemIdx] : null;

        if ( todoItem )
            openTask( todoList, todoItem.toJSON() );
    } );

    $("#mergetasks")[0].addEventListener( "click", () => {
        const todoItemNode = $("[aria-selected=true]")[0];

        $<HTMLInputElement>("#taskid")[0].value = todoItemNode?.id;
        todoList.mergeTask();
    } );

    // hacky
    (window as any).setSort = (method: SortMethod) => {
        todoList.sort( method );
    }
    (window as any).clearDate = () => {
        $<HTMLInputElement>("#date")[0].value = "";
    }

    $<HTMLButtonElement>("#show")[0].addEventListener( "click", () => { todoList.toggleShowCompleted() } );
    $<HTMLButtonElement>(".overlay")[0].addEventListener( "pointerdown", closeTask );
    $<HTMLButtonElement>("#cancel")[0].addEventListener( "click", closeTask );
    $<HTMLButtonElement>("#save")[0].addEventListener( "click", todoList.saveTask.bind(todoList) );
    $<HTMLButtonElement>("#delete")[0].addEventListener( "click", todoList.deleteTask.bind(todoList) );
    $<HTMLButtonElement>("#merge")[0].addEventListener( "click", todoList.mergeTask.bind(todoList) );
    $<HTMLButtonElement>( "#separate" )[0].addEventListener( "click", todoList.separateChildTasks.bind(todoList) );

    $<HTMLInputElement>("#label")[0].addEventListener( "keydown", (e) => {
        switch ( e.key )
        {
            case "Enter":
                $<HTMLButtonElement>("#save")[0].click();
                break;
            case "Escape":
                $<HTMLButtonElement>("#cancel")[0].click();
                break;
        }
    } );

    $<HTMLBodyElement>("body")[0].addEventListener( "keydown", (e) => {
        // Skip if dialog open
        if ( $<HTMLElement>("#overlay")[0].ariaHidden === "false" )
            return;

        const isTask = document.activeElement?.ariaChecked !== null;
        const todoItemIdx = isTask ? todoList.todos.findIndex( todoItem => todoItem.id === document.activeElement!.id ) : -1;
        const todoItem = todoItemIdx >= 0 ? todoList.todos[todoItemIdx] : null;

        // Tree navigation
        switch ( e.key )
        {
            case "Enter":
                if ( todoItem )
                    openTask( todoList, todoItem.toJSON() );
                break;

            case " ":
                if ( todoItem )
                {
                    todoItem.done = !todoItem.done;
                    todoItem.dispatchEvent(new CustomEvent( "change", { detail: todoItem } ));
                }
                break;

            case "ArrowUp":
                if ( todoItem )
                {
                    const next = todoList.prevSibling( todoItem );
                    // TODO: for parent items this doesn't make sense
                    if ( next && ( todoList.showCompleted || !next.done ) )
                        todoList.select([next]);
                    else
                    {
                        todoList.select([]);
                        document.body.scrollTop = 0;
                        $<HTMLInputElement>("header > input")[0].focus();
                    }
                }
                break;

            case "ArrowDown":
                if ( todoItem )
                {
                    const next = todoItem.expanded ? todoList.firstChild( todoItem ) : todoList.nextSibling( todoItem );
                    if ( next )
                        todoList.select([next]);
                }
                break;

            case "ArrowLeft":
                if ( todoItem )
                {
                    if ( todoItem.expanded === true )
                    {
                        todoItem.expanded = false;
                        todoItem.dispatchEvent(new CustomEvent( "change" ));
                        todoList.select([todoItem]);
                    }
                    else
                    {
                        const next = todoList.parentItem( todoItem );
                        if ( next )
                            todoList.select([next]);
                        else
                        {
                            todoList.select([]);
                            document.body.scrollTop = 0;
                            $<HTMLInputElement>("header > input")[0].focus();
                        }
                    }
                }
                break;

            case "ArrowRight":
                if ( todoItem )
                {
                    if ( todoItem.expanded )
                    {
                        const next = todoList.firstChild( todoItem );
                        if ( next )
                            todoList.select([next]);
                    }
                    else if ( todoItem.children.size )
                    {
                        // TODO: exclude done/hidden children?
                        todoItem.expanded = true;
                        todoItem.dispatchEvent(new CustomEvent( "change" ));
                        todoList.select([todoItem]);
                    }
                    else
                    {
                        // TODO
                        const next = todoList.lastSibling( todoItem );
                        if ( next )
                            todoList.select([next]);
                    }
                }
                break;
            default:
                return;
        }

        // Don't let UI handle it
        if ( isTask )
            e.preventDefault();
    } );


    $("#barcode-wrapper")[0].addEventListener( "click", async () => {
        const barcode = await startScan();
        if ( barcode )
        {
            $<HTMLAudioElement>("#wl3-scan")[0].play();
            todoList.addItem( barcode );
        }
    } );


    const floatButton = $<HTMLButtonElement>("#floatbar button")[0];
    const floatInput = $<HTMLInputElement>("#floatbar input")[0];
    floatButton.addEventListener("click", (e) => {
        if ( e.target !== floatButton)
            return;

        // No keyboard "clicks"
        if ( (e as PointerEvent).pointerId === -1 || (e as PointerEvent).pointerType === "" )
            return;

        if ( floatButton.ariaExpanded === "true" )
        {
            floatButton.ariaExpanded = "false";
        }
        else
        {
            floatButton.ariaExpanded = "true";
            floatInput.focus();
        }
    });

    floatInput.addEventListener( "keydown", (e) => {
        switch ( e.key )
        {
            case "Enter":
                todoList.addItem( floatInput.value );
                floatInput.value = "";
                floatButton.ariaExpanded = "false";
                floatInput.blur();
                // Stop from opening the edit task dialog
                e.stopPropagation();
                break;
        }
    } );


    const newInput = $<HTMLInputElement>("header > input")[0];
    newInput.focus();
    newInput.addEventListener( "keydown", (e) => {
        switch ( e.key )
        {
            case "Enter":
                todoList.addItem( newInput.value );
                newInput.value = "";
                // Stop from opening the edit task dialog
                e.stopPropagation();
                break;
            case "ArrowDown":
                const next = todoList.firstSibling( todoList.todos[0] );
                if ( next )
                    todoList.select([next]);

                break;
        }

    } );
}
